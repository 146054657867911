const PoemistLogo = () => (
  <svg viewBox="0 0 380.5 128.1" style={{ minWidth: "5rem" }}>
    <path
      d="M29.8,0c11.3,0,23,0.2,33.8,3.9c26.7,10.4,35.1,41,23,65.9C75.2,90.2,50.9,92.9,29.8,92.9h-23v31.8c0,4.5-6.7,4.6-6.7,0V3.5
      C0,0.9,2.6,0,4.8,0H29.8z M6.7,6.5v79.9h24.7c17.6,0,38.6-2.2,49-18.4V68C87.9,54.8,88,35,78.9,22.5C68.7,8.4,49,6.5,33.1,6.5H6.7z"
    />
    <path
      d="M114,69.3c28.2,0,28.1,43.6,0,43.6C86,112.8,85.8,69.3,114,69.3z M114,75.7c-19.9,0-19.8,30.6,0,30.7
      C133.6,106.5,133.6,75.7,114,75.7z"
    />
    <path
      d="M150.5,93.3c0.9,9.9,10.5,14.9,19.7,12.5c4.2-1.1,6.6-4.8,10.4-6.1c3.7-1.3,6.6,2.4,3.5,5.5c-8,8-21.6,10.7-31.3,4.1
      c-9.1-6.1-11.2-19.7-6.3-29.1c5-9.5,17.2-13.7,27.1-9.5c4.9,2.1,9,6,11.2,10.9c1.5,3.4,3.9,11.7-2.1,11.7H150.5z M179.7,87
      c-3.4-14.8-25.7-15.1-28.9-0.1L179.7,87z"
    />
    <path
      d="M206.6,76.1c0,0.3,0,0.7-0.1,1c6.4-5.2,24-14.1,27.2-0.6c9.3-6.9,28.4-13.1,28.4,4.6v27.7c0,4.3-6.5,4.6-6.5,0V84.3
      c0-3.9,0.1-7.9-4.5-8.6c-5.8-0.9-12.9,5.2-17.1,8.3v24.8c0,4.3-6.4,4.6-6.4,0v-23c0-4.1,0.2-9.6-5-10.1c-5.6-0.5-12.4,6.2-16.3,9.5
      v23.6c0,4.3-6.4,4.6-6.4,0V72.7c0-1.8,1.3-3.6,3.3-3.4C206.5,69.6,206.6,73.7,206.6,76.1z"
    />
    <path
      d="M284.8,39.7c6.5,0,6.5,10.2,0,10.2C278.2,49.9,278.3,39.7,284.8,39.7z M288,108.8c0,4.2-6.4,4.5-6.4,0V72.7
      c0-4.3,6.4-4.6,6.4,0.1V108.8z"
    />
    <path
      d="M311,105.3c6.9,2.6,16.4-2.8,6.8-7.6c-7.6-3.8-16.1-5.6-13.6-16.4c2-8.6,11.2-12.8,19.4-11.9c2.6,0.3,8.6,1.4,9.3,4.6
      c0.7,3.6-2.8,4.2-5.5,3.1h0.1c-5.7-2.5-20.6-1-17.3,8.8c2.2,6.5,13.4,5.1,17.1,10.8c5.6,8.6-3.8,16.6-12.1,16.1
      c-2.6-0.2-8.3-1-9.4-3.7c-1.2-3,2.4-6.5,5-3.9L311,105.3z"
    />
    <path
      d="M356.4,95.5c0,8.2,5.1,14,13.5,9.5c2.4-1.3,4.9-5.7,7.5-5.8c3.1,0,4,3.6,2,5.6l0.1-0.1c-4.4,6.4-12.3,9.4-19.9,7.6
      c-8.1-2-9.8-9.4-9.6-16.8l0.3-18.9h-10.2c-4.2,0-4.5-6.3,0-6.3h10.4l0.9-28.7c0.2-4.3,6.3-4.5,6.3,0L357,70.3h16
      c4.1,0,4.4,6.3,0,6.3h-16.2C356.8,82.9,356.4,89.2,356.4,95.5z"
    />
  </svg>
)

export default PoemistLogo
